import vuetify from './plugins/vuetify'
import CaseAnalysisApp from '@/widgets/CaseAnalysisApp.vue'
import CaseListApp from '@/widgets/CaseListApp.vue'
import FinApiWizard from '@/widgets/FinApiWizard.vue'
import Vue from 'vue'
// Global styles
import '@/assets/_global.scss'
import '@fortawesome/fontawesome-free/css/all.css'
import { CaseAnalysisAppProps } from '@/components/case-analysis/types'
import { CaseListProps } from '@/components/case-list/types'
import BackOfficeAreaTree from '@/components/backoffice-area-tree/BackOfficeAreaTree.vue'
import BackOfficeAreaTreeApp from '@/widgets/BackOfficeAreaTreeApp.vue'

function makeCaseAnalysisApp (props: CaseAnalysisAppProps): { app: Vue; eventBus: Vue } {
  const eventBus = new Vue()
  const app = new Vue({
    vuetify,
    provide: { eventBus },
    render: function (h) {
      return h(CaseAnalysisApp, {
        props
      })
    }
  })
  return {
    eventBus,
    app
  }
}

function makeFinApiWizard (props: {}): { app: Vue; eventBus: Vue } {
  const eventBus = new Vue()
  const app = new Vue({
    vuetify,
    provide: { eventBus },
    render: function (h) {
      return h(FinApiWizard, {
        props
      })
    }
  })
  return {
    eventBus,
    app
  }
}

function makeCaseListApp (props: CaseListProps): { app: Vue } {
  const app = new Vue({
    vuetify,
    render: function (h) {
      return h(CaseListApp, {
        props
      })
    }
  })
  return {
    app
  }
}

function makeBackOfficeAreaTreeApp (setCurrentArea: (area: number) => void): { app: Vue; refresh: () => void } {
  const eventBus = new Vue()
  eventBus.$on('selectArea', setCurrentArea)
  const refresh: () => void = () => {
    eventBus.$emit('refreshTree')
  }
  const app = new Vue({
    vuetify,
    provide: { eventBus },
    render: function (h) {
      return h(BackOfficeAreaTreeApp, {
      })
    }
  })
  return {
    app, refresh
  }
}

export default {
  makeCaseAnalysisApp,
  makeCaseListApp,
  makeBackOfficeAreaTreeApp,
  makeFinApiWizard
}
