<template>
  <div
    id="online-identification"
    class="case-editor-cta-bar"
  >
    <div class="case-editor-cta-bar-col-left-authentication">
      <v-icon
        size="70px"
        dark
        color="primary"
      >
        $idCard
      </v-icon>
    </div>

    <div class="case-editor-cta-bar-col-right-authentication">
      <h2>Online-Identifizierung über finAPI</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinApiIdentificationHeader'
}
</script>

<style >

</style>
