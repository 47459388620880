










import { Component, Inject, Vue } from 'vue-property-decorator'
import LoadingFrame from '@/components/frames/LoadingFrame'
import BackOfficeAreaTree from '@/components/backoffice-area-tree/BackOfficeAreaTree.vue'
import { BackOfficeRootArea } from '@/components/backoffice-area-tree/types'
import axios from 'axios'
@Component({
  components: { BackOfficeAreaTree, LoadingFrame }
})
export default class BackOfficeAreaTreeApp extends Vue {
  rootArea: BackOfficeRootArea | undefined

  @Inject() private eventBus!: Vue

  private api = axios.create()

  private rootUrl = '/backoffice/area/root'

  data () {
    return {
      rootArea: undefined
    }
  }

  async mounted () {
    await this.refresh()
    this.eventBus.$on('refreshTree', this.refresh)
  }

  async refresh () {
    const response = await this.api.get(this.rootUrl)
    this.rootArea = response.data[0]
  }
}
