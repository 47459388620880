<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <VApp>
    <FinApiIdentificationHeader />
    <v-stepper
      v-model="stepCounter"
    >
      <v-stepper-header
        id="finApiStepperHeader"
      >
        <v-stepper-step
          :complete="stepCounter > 1"
          step="1"
          style="margin: 0 10px 0 10px;"
        >
          Überprüfung der Daten
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="stepCounter > 2"
          step="2"
          style="margin: 0 10px 0 10px;"
        >
          Ergebnis der Online-Identifizierung
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form
            ref="form"
            v-model="valid"
            style="margin-top: 30px"
            @submit.prevent="submit"
          >
            <v-row>
              <v-col
                cols="4"
                align-center
              >
                <label for="finApiFirstname">Vorname*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiFirstname"
                  v-model="finApiWizard.firstName"
                  single-line
                  outlined
                  dense
                  name="firstName"
                  hide-details="auto"
                  :rules="[notNullRule, firstNameRule]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiLastName">Nachname*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiLastName"
                  v-model="finApiWizard.lastName"
                  single-line
                  outlined
                  dense
                  hide-details="auto"
                  name="lastName"
                  :rules="[notNullRule, ...lastNameRule]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiDateOfBirth">Geburtsdatum*</label>
              </v-col>
              <v-col cols="8">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="280px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatDateGer"
                      single-line
                      dense
                      outlined
                      readonly
                      hide-details="auto"
                      v-bind="attrs"
                      :rules="birthDateRule"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="finApiWizard.dateOfBirth"
                    no-title
                    scrollable
                    @input="menu = false "
                  />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiGender">Geschlecht*</label>
              </v-col>
              <v-col cols="8">
                <v-select
                  id="finApiGender"
                  v-model="finApiWizard.gender"
                  solo
                  flat
                  dense
                  outlined
                  hide-details="auto"
                  name="gender"
                  :items="items"
                  item-text="label"
                  item-value="abbr"
                  :rules="[notNullRule]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiTitle">Titel</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiTitle"
                  v-model="finApiWizard.title"
                  single-line
                  outlined
                  dense
                  hide-details="auto"
                  name="title"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiIban">IBAN*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiIban"
                  v-model="finApiWizard.iban"
                  single-line
                  outlined
                  dense
                  hide-details="auto"
                  name="iban"
                  :rules="ibanRules"
                  @change="resetValidation"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiStreet">Straße und Hausnummer*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiStreet"
                  v-model="finApiWizard.street"
                  single-line
                  outlined
                  dense
                  hide-details="auto"
                  name="street"
                  :rules="[notNullRule]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiZip">Postleitzahl*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiZip"
                  v-model="finApiWizard.zip"
                  single-line
                  outlined
                  dense
                  hide-details="auto"
                  name="zip"
                  :rules="[notNullRule]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label for="finApiCity">Ort*</label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  id="finApiCity"
                  v-model="finApiWizard.city"
                  single-line
                  outlined
                  dense
                  hide-details="auto"
                  name="city"
                  :rules="[notNullRule]"
                />
              </v-col>
            </v-row>
            <v-btn
              type="submit"
              color="primary"
              v-bind="attrs"
              :disabled="!valid"
              style="margin-top: 30px;"
              :loading="loadingSubmit"
              v-on="on"
            >
              Online-Identifizierung starten
            </v-btn>

            <div class="text-center">
              <v-dialog
                v-model="dialog"
                width="70%"
                persistent
                eager
              >
                <v-card>
                  <div
                    id="webFormContainer"
                  />
                </v-card>
              </v-dialog>
            </div>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2">
          <template>
            <v-container>
              <v-row
                align-center
                :class="verificationStatus.color.concat('Light')"
              >
                <v-col md="2">
                  <v-avatar
                    size="80"
                    :color="verificationStatus.color"
                  >
                    <v-icon
                      size="xxx-large"
                      dark
                    >
                      ${{ verificationStatus.icon.toString() }}
                    </v-icon>
                  </v-avatar>
                </v-col>
                <v-col
                  md="9"
                  style="width: 100%; white-space: break-spaces;"
                >
                  <p>{{ verificationStatus.statusText.toString() }}</p>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <v-container
            style="text-align :center"
            align-items="right"
          >
            <v-btn
              v-if="verificationStatus.status==='IN_PROGRESS' || verificationStatus.status==='NOT_STARTED'"
              class="mx-2"
              depressed
              large
              :loading="loadingRefresh"
              style="padding: 0 16px;"
              @click="refreshVerificationState"
            >
              <v-icon
                style="margin-right: 10px;"
              >
                $refresh
              </v-icon>
              Aktualisieren
            </v-btn>

            <v-btn
              v-else-if="verificationStatus.status==='COMPLETED'"
              color="primary"
              @click="refreshWindow"
            >
              Jetzt zur Übermittlung
            </v-btn>
            <v-btn
              v-else-if="verificationStatus.status==='FAILURE'"
              color="primary"
              @click="resetWizard"
            >
              Daten prüfen
            </v-btn>
            <v-btn
              v-else-if="verificationStatus.status==='ERROR'"
              color="primary"
              @click="resetWizard"
            >
              Erneut versuchen
            </v-btn>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </VApp>
</template>
<script>
import axios from 'axios'
import { DateTime } from 'luxon'
import { load } from '@finapi/web-form'
import FinApiIdentificationHeader from '@/components/finapi-verification/FinApiIdentificationHeader.vue'

const VerificationStatus = Object.freeze({
  IN_PROGRESS: {
    status: 'IN_PROGRESS',
    icon: 'waiting',
    color: 'inProgress',
    statusText: 'Wir haben Ihre Anfrage an finAPI weitergeleitet. \n' +
      'Den Status der Online-Identifizierung können Sie über »Aktualisieren« prüfen. '
  },
  NOT_STARTED: {
    status: 'NOT_STARTED',
    icon: 'waiting',
    color: 'neutral',
    statusText: 'finAPI übermittelt das Ergebnis der Prüfung an smartsteuer. \n' +
      'Bitte haben Sie einen Moment Geduld.\n' +
      'Falls Sie nicht automatisch zum Ergebnis der Prüfung weitergeleitet werden, klicken Sie bitte »Aktualisieren«.'
  },
  COMPLETED: {
    status: 'COMPLETED',
    icon: 'check',
    color: 'success',
    statusText: 'Ihre Online-Identifizierung über finAPI war erfolgreich. \n' +
      'Die Abgabe bei smartsteuer ist freigeschaltet. \n \n' +
      'Sie werden in 15 Sekunden automatisch zur Übermittlung weitergeleitet.'
  },
  FAILURE: {
    status: 'FAILURE',
    icon: 'xMark',
    color: 'failure',
    statusText: 'Ihre Online-Identifizierung war nicht erfolgreich.\n' +
      'Bitte prüfen Sie die erfassten Daten und starten die Online-Identifizierung erneut.\n ' +
      'Bitte wenden Sie sich gegebenenfalls zu Ihrer Unterstützung über »Support« an das smartsteuer-Team.\n'
  },
  ERROR: {
    status: 'ERROR',
    icon: 'xMark',
    color: 'failure',
    statusText: 'Es ist ein Fehler aufgetreten. \n' +
      'Bitte versuchen Sie es später nochmal. \n' +
      'Bitte wenden Sie sich gegebenenfalls zu Ihrer Unterstützung über »Support« an das smartsteuer-Team.\n'
  },
  COMPLETED_WITH_ERROR: {
    status: 'ERROR',
    icon: 'xMark',
    color: 'failure',
    statusText: 'Es ist ein Fehler aufgetreten. \n' +
      'Bitte versuchen Sie es später nochmal. \n' +
      'Bitte wenden Sie sich gegebenenfalls zu Ihrer Unterstützung über »Support« an das smartsteuer-Team.\n'
  }

})

export default {
  components: {
    FinApiIdentificationHeader
  },
  props: {
    finApiTargetEnv: {
      type: String
    }
  },
  data () {
    return {
      menu: false,
      valid: true,
      stepCounter: 1,
      items: [{ abbr: 'M', label: 'Männlich' }, { abbr: 'W', label: 'Weiblich' }, { abbr: 'D', label: 'Divers' }],
      verificationStatus: VerificationStatus.NOT_STARTED,
      finApiFormUrl: '#',
      errorStatus: undefined,
      loadingSubmit: false,
      loadingRefresh: false,
      dialog: false,
      intervalId: null,
      finApiWizard: {
        firstName: '',
        lastName: '',
        username: '',
        title: '',
        iban: '',
        gender: '',
        dateOfBirth: '1980-01-01',
        street: '',
        zip: '',
        city: ''
      },
      ibanRules: [
        v => !!v || 'Bitte füllen Sie dieses Feld aus',
        v => v.length <= 64 || 'Die IBAN darf nicht länger als 64 Zeichen sein.',
        v => this.handleIbanFieldErrors() === '' || this.handleIbanFieldErrors()
      ],
      birthDateRule: [
        v => !!v || 'Bitte füllen Sie dieses Feld aus',
        v => DateTime.fromFormat(v, 'dd.MM.yyyy').isValid || 'Das eingetragene Datum hat ein falsches Format. (01.01.1970)'
      ],
      notNullRule:
        v => !!v || 'Bitte füllen Sie dieses Feld aus.',
      firstNameRule:
        v => /^\D+$/.test(v) || 'Bitte benutzen Sie keine Ziffern.',
      lastNameRule: [
        v => /.*[a-zA-Z].*/.test(v) || 'Der Nachname enthält ungültige Zeichen.',
        v => v.length >= 2 || 'Der Nachname soll nicht kürzer als 2 Zeichen sein.',
        v => v.length <= 46 || 'Der Nachname soll nicht länger als 46 Zeichen sein.'
      ]
    }
  },
  computed: {
    formatDateGer () {
      return this.finApiWizard.dateOfBirth && DateTime.fromISO(this.finApiWizard.dateOfBirth).toFormat('dd.MM.yyyy')
    }
  },
  mounted () {
    this.prefill()
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  methods: {
    async prefill () {
      await axios.get('/api/prefill-finApi-summary')
        .then((res) => {
          this.finApiWizard = res.data
          // Gender comes as null when case is imported(clean case bug). Nulls lead the form to be validated as false from beginning
          if (this.finApiWizard.gender == null) {
            this.finApiWizard.gender = ''
          }
        })
    },
    handleIbanFieldErrors () {
      let ibanErrorMessage = ''
      if (this.errorStatus !== undefined && this.errorStatus.length > 0) {
        const error = this.errorStatus.find(e => e.errorType === 'VERIFICATION_ERROR_INVALID_IBAN' || e.errorType === 'BANK_NOT_FOUND')
        if (error) {
          ibanErrorMessage = error.errorMessage
        }
      }
      return ibanErrorMessage
    },
    submit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.loadingSubmit = true
      }
      axios.post('/api/verify-with-finApi',
        this.finApiWizard
      )
        .then((res) => {
          this.dialog = true
          const formId = res.data.redirectUrl.split('/').pop()
          this.createWebForm(formId)
        }
        )
        .catch((err) => {
          if ((!err.response.data['verification-error'] ||
            err.response.data['verification-error'].find(e => e.errorType === 'UNKNOWN' || e.errorType === 'INPUT_VALIDATION_ERROR')) &&
            err.request.status >= 400) {
            this.verificationStatus = VerificationStatus.ERROR
            this.stepCounter = 2
          } else {
            this.errorStatus = err.response.data['verification-error']
          }

          this.$refs.form.validate()
        })
        .finally(() => {
          this.loadingSubmit = false
        })
    },
    deleteVerification () {
      axios.delete('/api/clear-verification',
        { data: this.finApiWizard }
      )
    },
    refreshVerificationState () {
      this.loadingRefresh = true

      axios.post('/api/update-finApi-result',
        this.finApiWizard).then((res) => {
        const status = res.data.status
        if (status.toString() === 'COMPLETED') {
          clearInterval(this.intervalId)
          if (!res.data.successfullyVerified) {
            this.verificationStatus = VerificationStatus.FAILURE
          } else {
            this.verificationStatus = VerificationStatus[status]
            setTimeout(() => this.reloadSuccessButton(), 15 * 1000)
          }
        } else {
          this.verificationStatus = VerificationStatus[status]
        }
      }).catch(() => {
        clearInterval(this.intervalId)
      })
        .finally(() => {
          this.loadingRefresh = false
        })
    },
    reloadSuccessButton () {
      this.refreshWindow()
    },
    refreshWindow () {
      location.reload()
    },
    resetWizard () {
      this.verificationStatus = VerificationStatus.NOT_STARTED
      this.stepCounter = 1
    },
    resetValidation () {
      this.errorStatus = { errorType: 'NONE', errorMessage: '' }
      this.$refs.form.resetValidation()
    },
    createWebForm (formId) {
      this.verificationStatus = VerificationStatus.IN_PROGRESS
      const closeDialogAndMoveToNextStep = () => {
        this.dialog = false
        this.stepCounter = 2
      }

      const closeDialogAndMoveToStart = () => {
        this.dialog = false
        this.stepCounter = 1
        this.verificationStatus = VerificationStatus.NOT_STARTED
        this.deleteVerification()
        clearInterval(this.intervalId)
      }

      const updatePollingInterval = () => {
        this.intervalId = setInterval(this.refreshVerificationState(), 15 * 1000)
        setTimeout(() => {
          clearInterval(this.intervalId)
        }, 15 * 60 * 1000)
      }

      const updateVerificationStatusOnLoadError = () => {
        this.verificationStatus = VerificationStatus.FAILURE
        clearInterval(this.intervalId)
      }
      load(
        document.querySelector('#webFormContainer'),
        { token: formId, targetEnvironment: this.finApiTargetEnv },
        {
          onComplete: function () {
            closeDialogAndMoveToNextStep()
            updatePollingInterval()
          },
          onFail: function () {
            closeDialogAndMoveToStart()
          },
          onAbort: function () {
            closeDialogAndMoveToStart()
          },
          onLoadError: function () {
            updateVerificationStatusOnLoadError()
          }
        }
      )
    }
  }
}
</script>

<style lang="scss">

.v-stepper {
  margin: 20px 0 0;
}

.v-stepper__step__step {
  margin-right: 10px;
}

.formInfo {
  align-self: center;
}

a label {
  cursor: pointer;
  font-weight: bold;
  line-height: 27px;
}

.v-input {
  font-size: 13px;
  padding: 0;
}

.v-input input {
  padding: 0;
}

div.v-messages__message {
  white-space: pre-line;
}

</style>
