import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de'
  },
  icons: {
    iconfont: 'fa',
    values: {
      prev: 'fas fa-angle-left',
      next: 'fas fa-angle-right',
      expand: 'fas fa-angle-down',
      refresh: 'fa fa-refresh',
      check: 'fa fa-check',
      waiting: 'fa-regular fa-exclamation',
      xMark: 'fa fa-times',
      loading: 'fas fa-circle-notch fa-spin',
      idCard: 'fa fa-id-card'
    }
  },
  options: {
    customProperties: true
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#0080cc',
        secondary: '#a0b0bb',
        neutral: '#75828A',
        neutralLight: '#f0f3f6',
        success: '#5cb85c',
        successLight: '#eaf6ea',
        inProgress: '#ff7f01',
        inProgressLight: '#fffbf1',
        failure: '#F75D5D',
        failureLight: '#fdf3f0'
      }
    }
  }
})
